import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as COMMUNICATION from 'communication_linkroom_template';
import * as INTERFACE from 'linkroomv3_ui';
import { firstValueFrom, interval, Observable, Subscription } from 'rxjs';

import { PROFILE_PICTURE_FORMAT } from '@shared/utils/constants';
import { DefaultImage, RoomCameraContainer, SelfieCameraStatus } from '@shared/utils/enums';
import { base64ToBlob, readFile } from '@shared/utils/functions';
import { ImageData } from '@shared/utils/types';

@Component({
  selector: 'app-selfie',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './selfie.component.html',
  styleUrl: './selfie.component.css'
})
export class SelfieComponent {
  photoUrlTemp: string = DefaultImage.BLANK;
  snapStatus: string = '';
  countdownInterval: any;
  isTakingPhoto: boolean = false;
  isFile: boolean = false;

  showPhotoURL: boolean = false;
  cameraReady: boolean = false;
  imageData: ImageData | null = null;

  DefaultImage = DefaultImage;
  SelfieCameraStatus = SelfieCameraStatus;
  snapCameraStatus: SelfieCameraStatus = SelfieCameraStatus.TAKE_PHOTO;

  private timer: Observable<number> = interval(1000);
  private timerSub?: Subscription;
  cameras: any[] = [];

  @ViewChild('fileUploader') fileUploader?: ElementRef<HTMLInputElement>;
  @Input() isCameraAvailable: boolean = true;
  @Input() canClose: boolean = true;
  @Input() isCameraEnabled: boolean | undefined = false;
  @Input() title: string = '';
  @Input() cancelText: string = '';

  @Output() close: EventEmitter<null> = new EventEmitter();
  @Output() continue: EventEmitter<ImageData> = new EventEmitter();
  @Output() skip: EventEmitter<null> = new EventEmitter();

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.resetVariables();

    try {
      this.loadDevices();
      this.isCameraEnabled = true;
    } catch (error: any) {
      console.error(`===error====`);
      console.error(error);
    }
  }

  ngOnChanges(): void {
    this.resetVariables();
  }

  async resetVariables() {
    this.photoUrlTemp = DefaultImage.BLANK;
    this.snapStatus = await firstValueFrom(this.translateService.get('room.selfie.take-photo'));
    this.snapCameraStatus = SelfieCameraStatus.TAKE_PHOTO;
    this.showPhotoURL = false;
    this.cameraReady = true;

    this.timerSub = this.timer.subscribe(x => {
      this.timerSub?.unsubscribe();
    });
    // await COMMUNICATION.settings(null, null, true, true, false);
  }

  onClose() {
    this.close.emit(null);
  }

  onSkip() {
    this.skip.emit(null);
  }

  uploadSelfie() {
    this.continue.emit(this.imageData!);
  }

  startCountdown() {
    this.isTakingPhoto = true;
    this.showPhotoURL = false;

    this.photoUrlTemp = DefaultImage.BLANK;
    let counter = 3;
    this.snapStatus = counter.toString();

    this.countdownInterval = setInterval(async () => {
      counter--;

      if (counter == 0) {
        clearInterval(this.countdownInterval);
        this.countdownInterval = undefined;
        this.snapStatus = await firstValueFrom(this.translateService.get('room.selfie.snap'));
        this.snapCameraStatus = SelfieCameraStatus.SNAP;
        setTimeout(async () => {
          await this.snapPhoto();
        }, 1000);
      } else {
        this.snapStatus = counter.toString();
      }
    }, 1000);
  }

  private async snapPhoto() {
    const data = await COMMUNICATION.getCurrentFrameDataSettings();

    this.imageData = await this.getPhotoData(data);
    this.photoUrlTemp = this.imageData?.base64Image!;
    this.isFile = false;
    this.isTakingPhoto = false;
    this.showPhotoURL = true;
    this.snapStatus = await firstValueFrom(this.translateService.get('room.selfie.take-again'));
    this.snapCameraStatus = SelfieCameraStatus.TAKE_AGAIN;
  }

  private async getPhotoData(data: any): Promise<ImageData | null> {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (!context) return null;

    canvas.width = data.width;
    canvas.height = data.height;

    const imageData = new ImageData(new Uint8ClampedArray(data.imageData), data.width, data.height);
    //

    const flippedData = new Uint8ClampedArray(imageData.data.length);

    for (let y = 0; y < data.height; y++) {
      for (let x = 0; x < data.width; x++) {
        const index = (y * data.width + x) * 4;
        const flippedIndex = (y * data.width + (data.width - 1 - x)) * 4;

        flippedData[flippedIndex] = imageData.data[index];
        flippedData[flippedIndex + 1] = imageData.data[index + 1];
        flippedData[flippedIndex + 2] = imageData.data[index + 2];
        flippedData[flippedIndex + 3] = imageData.data[index + 3];
      }
    }
    const flippedImageData = new ImageData(flippedData, data.width, data.height);
    context.putImageData(flippedImageData, 0, 0);
    //
    //  context.putImageData(imageData, 0, 0);
    const base64Image = canvas.toDataURL();

    const blob = await new Promise<any>(resolve =>
      canvas.toBlob(resolve, `image/${PROFILE_PICTURE_FORMAT}`, 1)
    );

    return { base64Image, blob };
  }

  async setProfilePic(event: Event) {
    let fileInput = event.target as HTMLInputElement;

    if (fileInput.files!.length > 0) {
      this.showPhotoURL = true;
      this.isTakingPhoto = false;
      this.photoUrlTemp = await readFile(fileInput.files!.item(0)!);
      const blob = base64ToBlob(this.photoUrlTemp, `image/${PROFILE_PICTURE_FORMAT}`);
      this.isFile = true;
      this.imageData = { base64Image: this.photoUrlTemp, blob };
    }
  }

  displayFileUpload() {
    this.fileUploader!.nativeElement.click();
  }

  //#region Agora
  loadDevices() {
    COMMUNICATION.requestPermissions()
      .then(
        results => {
          const temporalTracks = results.stream ? results.getTracks() : null;
          if (temporalTracks && temporalTracks.length > 0) {
            try {
              temporalTracks[0].stop();
              temporalTracks[1].stop();
            } catch (error) {
              console.error(error);
            }
          }

          COMMUNICATION.init()
            .then(async () => {
              const deviceSetting = await COMMUNICATION.settings(null, null, true, true, false);
              if (deviceSetting.cams.status == 'fulfilled') {
                this.cameras = deviceSetting.cams.value;
                var v = document.getElementById(RoomCameraContainer.SELFIE);
                COMMUNICATION.playVideoTrackSettings(v);
              } else {
                console.error(deviceSetting.cams.reason);
              }
            })
            .catch(error => {
              console.error(error);
            });

          COMMUNICATION.on('onCamerasAvailable', (data: any) => {
            this.onCamerasAvailable(data);
          });
        },
        err => {
          console.error(`===err====`);
          console.error(err);
          //  this.hasDeviceError = true;
          //  this.isDeviceLoading = false;
        }
      )
      .catch(cat => {
        console.error(`===cat====`);
        console.error(cat);
        //   this.hasDeviceError = true;
        //   this.isDeviceLoading = false;
      });
  }

  onCamerasAvailable(data: any) {
    this.cameras = data.cams;
  }
  //#endregion
}
