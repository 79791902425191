import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SoundManagerService {
  playSound(fileSrc: string, volume: number) {
    let audio = new Audio();
    audio.src = fileSrc;
    audio.volume = volume;
    audio.load();
    audio.play().catch(error => {
      console.error(`===error====`);
      console.error(error);
      audio.src = '';
    });
  }
}
