
import { EVENT } from "../../../communication/src";
import { MSG_CODE } from "../constants";
import { getPhotonIdByAgoraUserId, getAgoraUserIdByPhotoId, getActorPropertyByUserId } from "./networkingModuleController";
import { sendVideoTextureToProjector } from "./objectsModuleController"
import { isIOSDevice, getSpecs } from "../utils";

let networkingModule;
let communicationModule;
let options;
export let communicationConnected = false;

var remoteUsers = [];

var remoteUsersState = [];

let events = {};

let experienceReady = false;

let currentVideoElementId = "";
let camEnabled = false;
let micEnabled = false;

const activeStreams = new Map();

export var communicationSubscribe = (_event, listener) => {
    if (!events[_event]) {
        events[_event] = [];
    }
    events[_event].push(listener);
}

let emitEvent = (_event, data) => {
    if (events[_event]) {
        events[_event].forEach((listener) => {
            listener(data);
        });
    }
}

export function initCommunicationModule(_options) {
    return new Promise((resolve, reject) => {

        options = _options;
        communicationModule = options.modules.communicationModule;
        communicationModule.init().then(resolve).catch(reject);
        networkingModule = options.modules.networkingModule;
        communicationModule.on('user-published', handleUserPublished);
        communicationModule.on('user-unpublished', handleUserUnpublished);
        communicationModule.on('volume-indicator', handleUserVolumeIndicator);
        communicationModule.on("onCamerasAvailable", onCamerasAvailable)

        communicationModule.on("onMicrophonesAvailable", onMicrophonesAvailable)
        communicationModule.on("stop-screen", () => { emitEvent("stop-screen", {}); });

        communicationModule.on("user-share-published", handleUserSharePublished);
        communicationModule.on("user-share-unpublished", handleUserShareUnpublished);
        communicationModule.on("onDevicePermissionDenied", onDevicePermissionDenied)

        //network stats WIP rdf
        communicationModule.on(communicationModule.EVENT.NETWORK_QUALITY, handleNetworkQuality);
    });
}

export function initCommunicationWithoutModule(_options) {
    return new Promise((resolve, reject) => {
        options = _options;
        communicationConnected = true;
        emitEvent("communicationConnected", {});
        resolve();
    });
}

export function createLocalTracks() {

    currentVideoElementId = options.roomOptions.videoElementID;

    return new Promise((resolve, reject) => {
        communicationModule.createAndPublishLocalTracks(options.roomOptions.selectedCamID, options.roomOptions.selectedMicID, options.roomOptions.videoEnabled, options.roomOptions.audioEnabled, options.roomOptions.blurEnabled).then((tracksResponse) => {

            console.log(tracksResponse);

            const videoElement = document.getElementById(currentVideoElementId);
            sendVideoCamTexture(videoElement, true, options.userOptions.userId);

            if (tracksResponse.cam.status == "fulfilled") {
                communicationModule.playVideoCamera(videoElement);
                camEnabled = tracksResponse.cam.value != null ? true : false;

            } else {
                console.log(tracksResponse.cam.reason);
            }

            if (tracksResponse.mic.status == "fulfilled") {
                micEnabled = tracksResponse.mic.value != null ? true : false;
            } else {
                console.log(tracksResponse.mic.reason);
            }
            resolve();
        })
    });
}

function stopVideoTexture(uuid) {
    if (activeStreams.has(uuid)) {
        const stream = activeStreams.get(uuid);
        if (stream.videoElement.requestVideoFrameCallback && stream.callbackId) {
            stream.videoElement.cancelVideoFrameCallback(stream.callbackId);
        }
        activeStreams.delete(uuid);
    }
}



export function JoinChannelAndPublishTracks() {
    communicationModule.createClient();
    communicationModule.joinChannel(options.apikeys.communicationAppId, options.roomOptions.roomID, options.apikeys.agoraToken, options.userOptions.userId).then((response) => {
        communicationModule.publishLocalTracks(true);
    });
    communicationModule.joinShareScreenChanel(options.apikeys.communicationAppId, `${options.roomOptions.roomID}_screenShare`, options.apikeys.agoraScreenShareToken, options.userOptions.userId).then((response) => {

    });

    communicationConnected = true;
    emitEvent("communicationConnected", {});

}
export function rejoinChannel() {
    communicationModule.joinChannel(options.apikeys.communicationAppId, options.roomOptions.roomID, options.apikeys.agoraToken, options.userOptions.userId).then((response) => {
        communicationModule.publishLocalTracks(true);
    });
}

export function screenShare() {
    return new Promise((resolve, reject) => {
        communicationModule.createAndPublishShareTrack().then((response) => {
            const videoElement = document.getElementById(options.roomOptions.screenShareElementID);
            communicationModule.playScreenShare(videoElement);

            var data = {
                code: "onShareScreen",
                data: options.userOptions.userId
            }

            options.callbacks.defaultResponse(data);
            resolve(videoElement);
        });
    });
}


export function stopScreenShare() {
    return new Promise((resolve, reject) => {
        communicationModule.unpublishShareTrack().then((response) => {

            var data = {
                code: "onStopShareScreen",
                data: options.userOptions.userId
            }
            options.callbacks.defaultResponse(data);
            resolve();
        });
    });
}


function onCamerasAvailable(camerasAvailable) {

    var data = {
        code: "onCamerasAvailable",
        data: camerasAvailable
    }
    options.callbacks.defaultResponse(data);
}

function onDevicePermissionDenied() {
    var data = {
        code: "onDevicePermissionDenied",
        data: {}
    }
    options.callbacks.defaultResponse(data);
}

function onMicrophonesAvailable(microphonesAvailable) {
    var data = {
        code: "onMicrophonesAvailable",
        data: microphonesAvailable
    }
    //console.log(microphonesAvailable);
    options.callbacks.defaultResponse(data);
}

function handleUserPublished({ user, mediaType }) {
    //console.log("User published", user.uid, mediaType);

    if (mediaType === 'video') {
        const videoRemoteElement = document.createElement('video');
        videoRemoteElement.id = `player-${user.uid}`;
        videoRemoteElement.classList.add('player');
        videoRemoteElement.style.width = "100px";
        videoRemoteElement.style.height = "100px";
        var container = document.getElementById(options.roomOptions.remoteVideoContainerID);
        container.appendChild(videoRemoteElement);
        user.videoTrack.play(`player-${user.uid}`);

        sendVideoCamTexture(videoRemoteElement, false, user.uid);
    }
    if (mediaType === 'audio') {
        if (user.audioTrack) {
            user.audioTrack.play();
            let v = experienceReady ? 100 : 0;
            user.audioTrack.setVolume(v);
            remoteUsers.push(user);

            var data = {
                code: "OnMicrophoneStateChange",
                data: { muted: false, uid: user.uid }
            }
            options.callbacks.defaultResponse(data);
        }

        // if (user._audio_muted_ == false)
    }

    remoteUsersState.push(`${user.uid}&&${mediaType}`);

    if (experienceReady)
        sendMessageToOffScreen(false, mediaType, true, user.uid);


}

function handleUserSharePublished({ user, mediaType }) {
    if (mediaType === 'video') {
        var videoElement = document.getElementById(options.roomOptions.screenShareElementID);
        user.videoTrack.play(videoElement);

        var data = {
            code: "onShareScreen",
            data: user.uid
        }

        options.callbacks.defaultResponse(data);
    }
}

function handleUserShareUnpublished({ user, mediaType }) {
    var data = {
        code: "onStopShareScreen",
        data: user.uid
    }

    options.callbacks.defaultResponse(data);
}

function handleNetworkQuality(payload) {
    var data = {
        code: communicationModule.EVENT.NETWORK_QUALITY,
        data: payload
    }

    options.callbacks.defaultResponse(data);
}

export function experienceReadyOnCommunication() {
    experienceReady = true;
    remoteUsers.forEach(user => {
        if (user.audioTrack) {
            user.audioTrack.setVolume(100);
        }
    });

    if (communicationModule) {
        communicationModule.upVolume();
        setDeviceStateOnLinkpads();
    }


}

export function leaveChannel() {
    if (communicationModule) {
        communicationModule.leaveChannel();
        communicationConnected = false;
    }
}

function handleUserUnpublished({ user, mediaType }) {
    //console.log("User unpublished", user.uid, mediaType);

    if (mediaType === 'video') {
        var userElement = document.getElementById(`player-${user.uid}`);
        userElement?.remove();

        stopVideoTexture(user.uid);
    }
    if (mediaType === 'audio') {
        remoteUsers = remoteUsers.filter(u => u.uid != user.uid);

        var data = {
            code: "OnMicrophoneStateChange",
            data: { muted: true, uid: user.uid }
        }
        options.callbacks.defaultResponse(data);
    }

    remoteUsersState = remoteUsersState.filter(u => u != `${user.uid}&&${mediaType}`);

    if (experienceReady)
        sendMessageToOffScreen(false, mediaType, false, user.uid);
}

function handleUserVolumeIndicator(volumes) {
    //console.log(volumes, volumes.length);
    volumes.volumes.forEach((volume) => {
        // console.log(`UID: ${volume.uid}, Nivel de Volumen: ${volume.level}`);
        let id = getPhotonIdByAgoraUserId(volume.uid);

        if (id) {
            if (volume.level > 65) {
                // console.log(`El usuario con UID ${volume.uid} está hablando.`, volume.level);            
                var msg = {
                    code: "volume",
                    data: {
                        userId: id,
                        state: 2
                    }
                }
                options.workers.offscreenCanvasWorker.postMessage(msg);

            } else if (volume.level == 0) {
                // console.log(`El usuario con UID ${volume.uid} está hablando.`, volume.level);
                var msg = {
                    code: "volume",
                    data: {
                        userId: id,
                        state: 0
                    }
                }
                options.workers.offscreenCanvasWorker.postMessage(msg);
            } else {
                // console.log(`El usuario con UID ${volume.uid} está hablando.`, volume.level);
                var msg = {
                    code: "volume",
                    data: {
                        userId: id,
                        state: 1
                    }
                }
                options.workers.offscreenCanvasWorker.postMessage(msg);
            }
        }
    });
}

export function setDeviceStateOnNewRemoteLinkpadConnected(photonId) {

    setTimeout(() => {
        var userFound = getAgoraUserIdByPhotoId(photonId);
        if (userFound) {
            remoteUsersState.forEach(remoteUser => {
                var [agoraUserId, mediaType] = remoteUser.split("&&");
                if (agoraUserId == userFound.agoraUserId) {
                    sendMessageToOffScreen(false, mediaType, true, agoraUserId);
                }
            });
        }
    }, 500);

}

function setDeviceStateOnLinkpads() {

    if (camEnabled)
        sendMessageToOffScreen(true, "video", options.roomOptions.videoEnabled, options.userOptions.userId);

    if (micEnabled)
        sendMessageToOffScreen(true, "audio", options.roomOptions.audioEnabled, options.userOptions.userId);

    console.log(remoteUsersState);


    remoteUsersState.forEach(remoteUser => {
        var [agoraUserId, mediaType] = remoteUser.split("&&");
        sendMessageToOffScreen(false, mediaType, true, agoraUserId);
    });
}


export function enableAudio(enable) {
    micEnabled = enable;
    communicationModule.enableAudio(enable).then((response) => {
        sendMessageToOffScreen(true, "audio", enable, options.userOptions.userId)
    });
}


export function enableVideo(enable) {
    camEnabled = enable;
    communicationModule.enableVideo(enable).then((response) => {
        if (response.enabled) {
            const videoElement = document.getElementById(currentVideoElementId);
            communicationModule.playVideoCamera(videoElement);
        }
        sendMessageToOffScreen(true, "video", enable, options.userOptions.userId)
    });
}

export function enableBlurVideo(enable) {
    communicationModule.enableBlurVideo(enable).then((response) => { });

}

export function enableBlurVideoSettings(enable) {
    communicationModule.enableBlurVideoSettings(enable).then((response) => { });

}


export function switchVideoElementId(videoElementId) {
    currentVideoElementId = videoElementId;
    const videoElement = document.getElementById(currentVideoElementId);
    if (camEnabled) {
        communicationModule.playVideoCamera(videoElement);
    }
}

export function getCurrentFrameData() {
    return new Promise((resolve, reject) => {
        communicationModule.getCurrentFrameData().then((response) => {
            resolve(response);
        });
    });
}

export function switchCamera(deviceId) {
    communicationModule.switchCamera(deviceId).then((response) => {
        // console.log("switch camera response", response);
    });
}

export function switchMicrophone(deviceId) {
    communicationModule.switchMicrophone(deviceId).then((response) => {
        // console.log("switch microphone response", response);
    });
}

function sendMessageToOffScreen(local, type, isEnable, agoraId) {
    setTimeout(() => {
        var user = getPhotonIdByAgoraUserId(agoraId);
        // console.log("user", user);

        let actorProperty = null;

        if (user) {
            actorProperty = getActorPropertyByUserId(user.userId);
        }

        var msg = {
            code: type == "audio" ? MSG_CODE.COMMUNICATION_AUDIO_ENABLED : MSG_CODE.COMMUNICATION_VIDEO_ENABLED,
            data: {
                enable: isEnable,
                local: local,
                userId: user ? user.userId : 0,
                userName: actorProperty ? actorProperty.userName : "Linkroom"
            }
        }
        options.workers.offscreenCanvasWorker.postMessage(msg);

        var data = {
            code: type == "audio" ? "onMicrophoneEnabled" : "onCameraEnabled",
            data: {
                userId: user ? user.userId : agoraId,
                type: type,
                enable: isEnable
            }
        }
        options.callbacks.defaultResponse(data);
    }, 500);
}

function sendVideoCamTexture(videoElement, local, uid) {

    var specs = getSpecs();
    if (local) {
        if (specs.deviceType == 'Mobile') return;
        if (specs.deviceType === "Desktop" && specs.info.dedicated == false) return;
    }

    stopVideoTexture(uid);

    let lastTime = 0;
    // var fps = specs.deviceType == 'Mobile' ? 8 : 15;
    var fps = 8;
    const frameInterval = 1000 / fps;

    // let lastTime = 0;

    const captureFrame = async (now) => {
        const timeSinceLastFrame = now - lastTime;

        // const timeSinceLastFrame = now - lastTime;

        if (videoElement.readyState >= videoElement.HAVE_CURRENT_DATA && timeSinceLastFrame >= frameInterval) {


            // if (timeSinceLastFrame >= 1000 / 1) {
            const frame = new VideoFrame(videoElement, {
                format: "RGBA",
                timestamp: videoElement.currentTime * 1000
            });

            const resizedFrame = await resizeFrame(frame);
            frame.close();


            var user = getPhotonIdByAgoraUserId(uid);
            var msg = {
                code: MSG_CODE.COMMUNICATION_SEND_LINKPAD_VIDEO,
                data: {
                    userId: user ? user.userId : 0,
                    local: local,
                    frame: resizedFrame
                }
            }
            options.workers.offscreenCanvasWorker.postMessage(msg, [msg.data.frame]);
            lastTime = now;
            // }

        }
        // lastTime = now;
        if (videoElement.requestVideoFrameCallback) {
            const callbackId = videoElement.requestVideoFrameCallback(captureFrame);
            if (activeStreams.get(uid)) {
                activeStreams.get(uid).callbackId = callbackId;
            }
        }
    };

    activeStreams.set(uid, {
        videoElement,
        callbackId: null
    });
    captureFrame(performance.now());
}

async function resizeFrame(frame) {
    try {
        var specs = getSpecs();

        var newHeight = 90;

        const aspectRatio = frame.displayWidth / frame.displayHeight;
        const newWidth = Math.round(newHeight * aspectRatio);

        const offscreen = new OffscreenCanvas(newWidth, newHeight);
        const ctx = offscreen.getContext("2d");

        ctx.drawImage(frame, 0, 0, newWidth, newHeight);
        frame.close();

        return new VideoFrame(offscreen, { timestamp: frame.timestamp });
    } catch (error) {
        console.error("Error al redimensionar VideoFrame:", error);
        return frame;
    }
}


function sendMuteList() {
    console.log("sendMuteList", remoteUsers);
}