import { onSelectSceneObject, onSceneObjectUpdate } from './objectsModuleController';
import {
    onSceneObjectChange,
    showSceneObjectPreview,
    hideSceneObjectPreview,
    showDefaultCursor,
    hideDefaultCursor,
    showCreateObjectCursor,
    showHoverObjectCursor
} from "./viewerModuleController";
import { MSG_CODE } from '../constants';
import { v4 as uuidV4 } from 'uuid';
import { Vector3 } from 'three';
import { getAgoraUserIdByPhotoId } from './networkingModuleController';
let navigationModule;
let physicsReady = true;

let navigationPromises = {};

let options;

export function setupDOMNavigationModuleController(_options) {
    options = _options;
}

export function setNavigationModule(_navigationModule) {
    navigationModule = _navigationModule;
}

export function initNavigationModule(args) {
    return new Promise((resolve, reject) => {
        if (navigationModule) {
            navigationModule.init({
                camera: args.camera,
                inputElement: args.inputElement,
                THREE: args.THREE,
                clock: args.clock
            });

            navigationModule.initPhysics({
                scene: args.sceneryGroup,
                sceneObjectsGroup: args.sceneObjectsGroup,
                collider: args.collisionGroup,
                navMeshGroup: args.navMeshGroup,
                navMeshLayer: args.navMeshLayer,
                creationSurfaceGroup: args.creationSurfaceGroup,
                creationSurfaceLayer: args.creationSurfaceLayer,
                collisionLayer: args.collisionLayer,
                sceneObjectsLayer: args.sceneObjectsLayer,
                useNewPathfinding: args.useNewPathfinding
            }).then(() => {
                physicsReady = true;
                navigationModule.subscribe('playerMovement', onPlayerMove);
                navigationModule.subscribe('playerRotation', onPlayerRotate);
                // navigationModule.subscribe( 'onUpdateSceneObject', onTransformSceneObject);      

                navigationModule.subscribe('onUpdateSceneObject', onSceneObjectUpdate);
                navigationModule.subscribe('onSelectSceneObject', onSelectSceneObject);
                navigationModule.subscribe('onChangeSceneObject', onSceneObjectChange);
                navigationModule.subscribe('sendHostAction', onHostAction);
                navigationModule.subscribe('finishPathfindingMovement', onFinishPathfindingMovement);
                navigationModule.subscribe('actionPositionIndicator', onIndicatorPositionUpdate);
                navigationModule.subscribe('previewIndicatorRaycast', onPreviewIndicatorPositionUpdate);
                navigationModule.subscribe('hidePreview', onHidePreview);
                navigationModule.subscribe('defaultCursorRaycast', onDefaultCursorPositionUpdate);
                navigationModule.subscribe('hideDefaultCursorRaycast', onHideDefaultCursorPositionUpdate);
                navigationModule.subscribe('createObjectCursorRaycast', onCreateObjectCursor);
                navigationModule.subscribe('hoverObjectCursorRaycast', onHoverObjectCursor);
                navigationModule.subscribe('moveWithPathfindingFinished', onMoveWithPathfindingFinished);
                navigationModule.subscribe('rotateCamera', onRotateCamera);
                // workerController.sendMessageToViewer('physicsReady', {});

                resolve();
            });
        } else {
            reject();
        }
    });

}
export function onPlayerMoveSubcribe(value) {
    if (navigationModule) {
        if (value) {
            navigationModule.subscribe('playerMovement', onPlayerMove);
            navigationModule.subscribe('playerRotation', onPlayerRotate);
        }
        else {
            navigationModule.unsubscribe('playerMovement', onPlayerMove);
            navigationModule.unsubscribe('playerRotation', onPlayerRotate);
        }
    }
}
function onPlayerMove(data) {

    let msg = {
        code: MSG_CODE.NETWORKING_SEND_TRANSFORM_POSITION,
        data: { position: [data.x, data.y, data.z] }
    }

    postMessage(msg);
}

function onPlayerRotate(data) {
    let msg = {
        code: MSG_CODE.NETWORKING_SEND_TRANSFORM_ROTATION,
        data: { rotation: [data.x, data.y, data.z, data.w] }
    }
    postMessage(msg);
}

export function onPreviewIndicatorPositionUpdate(data) {
    showSceneObjectPreview(data);
}

export function onHidePreview() {
    hideSceneObjectPreview();
}

export function onDefaultCursorPositionUpdate(data) {
    showDefaultCursor(data);
}

export function onHideDefaultCursorPositionUpdate() {
    hideDefaultCursor();
}

export function onCreateObjectCursor(data) {
    showCreateObjectCursor(data);
}

export function onHoverObjectCursor() {
    showHoverObjectCursor();
}

export function setTransformMode(mode) {
    navigationModule.setMode(mode);
}


export function getSelectedSceneObject(_uuid) {
    return new Promise((resolve, reject) => {
        try {
            //DOM          
            let validate = window;

            const uuidPromise = uuidV4();
            navigationPromises[uuidPromise] = { resolve, reject };

            let msg = {
                code: 'navigation_selected_sceneObject',
                data: {
                },
                uuid: uuidPromise
            }

            options.workers.offscreenCanvasWorker.postMessage(msg);
        } catch (error) {

            //OffScreen
            let msg = {
                code: 'navigationResolve',
                data: navigationModule.getCurrentSceneObject() != undefined ? navigationModule.getCurrentSceneObject().userData : undefined,
                uuid: _uuid
            }

            postMessage(msg);
        }
    })
}

export function getSceneObject(_uuid) {
    return new Promise((resolve, reject) => {
        try {
            //DOM          
            let validate = window;

            const uuidPromise = uuidV4();
            navigationPromises[uuidPromise] = { resolve, reject };

            let msg = {
                code: 'getSceneObject',
                data: {
                },
                uuid: uuidPromise
            }

            options.workers.offscreenCanvasWorker.postMessage(msg);
        } catch (error) {

            //OffScreen
            let msg = {
                code: 'navigationResolve',
                data: navigationModule.getCurrentSceneObject().userData,
                uuid: _uuid
            }

            postMessage(msg);
        }
    })
}


export function getNavigationRaycast(_uuid) {
    return new Promise((resolve, reject) => {
        try {
            //DOM          
            let validate = window;

            const uuidPromise = uuidV4();
            navigationPromises[uuidPromise] = { resolve, reject };

            let msg = {
                code: 'createRaycast',
                data: {
                },
                uuid: uuidPromise
            }

            options.workers.offscreenCanvasWorker.postMessage(msg);
        } catch (error) {

            //OffScreen
            navigationModule.createRaycastForNewObject().then((intersection) => {
                let msg = {
                    code: 'navigationResolve',
                    data: intersection,
                    uuid: _uuid
                }

                postMessage(msg);
            }).catch((e) => {
                let msg = {
                    code: 'navigationReject',
                    data: e,
                    uuid: _uuid
                }

                postMessage(msg);
            });
        }
    })
}

export function getPlayerHeight(_msg) {
    return new Promise((resolve, reject) => {


        try {
            let validate = window;

            const promiseUuid = uuidV4();
            navigationPromises[promiseUuid] = { resolve, reject };

            let msg = {
                code: 'getPlayerHeight',
                data: {},
                uuid: promiseUuid
            }
            options.workers.offscreenCanvasWorker.postMessage(msg);

        } catch (error) {
            if (navigationModule) {
                navigationModule.getPlayerHeight().then((height) => {

                    let msg = {
                        code: 'navigationResolve',
                        data: height,
                        uuid: _msg.uuid
                    }
                    postMessage(msg);
                })
            }
        }



    });
}

export function setEnableTransformControls(_msg) {
    return new Promise((resolve, reject) => {

        try {
            let validate = window;
            const promiseUuid = uuidV4();
            navigationPromises[promiseUuid] = { resolve, reject };
            let msg = {
                code: 'setEnableTransformControls',
                data: _msg.data,
                uuid: promiseUuid
            }
            options.workers.offscreenCanvasWorker.postMessage(msg);

        } catch (error) {
            if (navigationModule) {
                navigationModule.setEnableTransformControls(_msg).then(() => {

                    let msg = {
                        code: 'navigationResolve',
                        data: {},
                        uuid: _msg.uuid
                    }
                    postMessage(msg);
                })
            }
        }



    });
}


export function enablePlayerMovementAndRotation(_msg) {
    return new Promise((resolve, reject) => {
        try {
            let validate = window;

            const promiseUuid = uuidV4();
            navigationPromises[promiseUuid] = { resolve, reject };

            let msg = {
                code: 'enablePlayerMovementAndRotation',
                data: _msg.data,
                uuid: promiseUuid
            }
            options.workers.offscreenCanvasWorker.postMessage(msg);

        } catch (error) {
            navigationModule.enablePlayerMovementAndRotation(_msg).then(() => {
                let msg = {
                    code: 'navigationResolve',
                    data: {},
                    uuid: _msg.uuid
                }
                postMessage(msg);
            })
        }
    });
}

export function setCurrentSceneObject(_msg) {
    return new Promise((resolve, reject) => {
        try {
            //DOM          
            let validate = window;
            const uuidPromise = uuidV4();
            navigationPromises[uuidPromise] = { resolve, reject };

            let msg = {
                code: 'setCurrentSceneObject',
                data: { uuid: _msg.data.uuid },
                uuid: uuidPromise
            }

            options.workers.offscreenCanvasWorker.postMessage(msg);
        } catch (error) {
            navigationModule.setCurrentSceneObject(_msg.data.uuid).then(() => {
                let msg = {
                    code: 'navigationResolve',
                    data: {},
                    uuid: _msg.uuid
                }
                postMessage(msg);
            })
        }
    })
}

export function orbitToSceneObject(_msg) {
    return new Promise((resolve, reject) => {
        try {
            //DOM      
            let validate = window;
            const uuidPromise = uuidV4();

            navigationPromises[uuidPromise] = { resolve, reject };
            let msg = {
                code: 'orbitToSceneObject',
                data: { uuid: _msg.data.uuid },
                uuid: uuidPromise
            }
            options.workers.offscreenCanvasWorker.postMessage(msg);
        } catch (error) {
            onPlayerMoveSubcribe(false);
            navigationModule.orbitToSceneObject(_msg.data.uuid).then(() => {

                let msg = {
                    code: 'navigationResolve',
                    data: { uuid: _msg.data.uuid },
                    uuid: _msg.uuid
                }
                postMessage(msg);
            })
        }
    })
}

export function exitOrbitMode(_msg) {
    return new Promise((resolve, reject) => {
        try {
            //DOM      
            let validate = window;
            const uuidPromise = uuidV4();

            navigationPromises[uuidPromise] = { resolve, reject };
            let msg = {
                code: 'exitOrbitMode',
                data: {},
                uuid: uuidPromise
            }
            options.workers.offscreenCanvasWorker.postMessage(msg);
        } catch (error) {
            onPlayerMoveSubcribe(true);
            navigationModule.exitOrbitMode(_msg).then(() => {
                let msg = {
                    code: 'navigationResolve',
                    data: {},
                    uuid: _msg.uuid
                }
                postMessage(msg);
            })
        }
    })
}

export function navigationResolve(msg) {
    navigationPromises[msg.uuid].resolve(msg.data);
}

export function navigationReject(msg) {
    navigationPromises[msg.uuid].reject(msg.data);
}

export function getPlayerPosition() {
    let pos = navigationModule.getCameraPosition();
    let msg = {
        code: MSG_CODE.ACTION_RECIVE_POSITION,
        data: { position: pos }
    }
    postMessage(msg);
}

export function moveToTarget(position, lookDirection = new Vector3(0, 1, 0)) {
    const posv3 = new Vector3(position.x, position.y, position.z);
    const lookDirectionv3 = new Vector3(lookDirection.x, lookDirection.y, lookDirection.z);
    const pos = [position.x, position.y, position.z];
    //navigationModule.moveToTarget(posv3, lookDirectionv3);
    navigationModule.movePlayerInmediatlyTo(pos);
    setTimeout(() => {
        lookAtTarget(lookDirection);
    }, 100);
}

export function lookAtTarget(position) {
    const posv3 = new Vector3(position.x, position.y, position.z);
    navigationModule.lookAtTarget(position);
}

export function movePlayerInmediatlyTo(position) {
    if (navigationModule) {
        navigationModule.movePlayerInmediatlyTo(position);
    }
}


export function lookAtTargetInmediatly(lookAt) {
    if (navigationModule) {
        navigationModule.lookAtTargetInmediatly(lookAt);
    }
}

export function actionOrbitToSceneObject(uuid) {
    if (navigationModule) {
        navigationModule.orbitToSceneObject(uuid);
    }
}

export function actionOrbitToPosition(position) {
    if (navigationModule) {
        onPlayerMoveSubcribe(false);
        navigationModule.orbitToPosition(position);
    }
}

export function actionExitOrbit() {
    return new Promise((resolve, reject) => {
        try {
            let validate = window;
            const promiseUuid = uuidV4();
            navigationPromises[promiseUuid] = { resolve, reject };

            let msg = {
                code: 'actionExitOrbit',
                data: {},
                uuid: promiseUuid
            }
            options.workers.offscreenCanvasWorker.postMessage(msg);
        } catch (error) {
            if (navigationModule) {
                onPlayerMoveSubcribe(true);
                navigationModule.exitOrbitMode();
            }
        }
    });
}

export function start360CameraRotation() {
    if (navigationModule) {
        navigationModule.start360CameraRotation();
    }
}

export function forceUnselectSceneObject(_msg) {
    return new Promise((resolve, reject) => {
        try {
            let validate = window;

            const promiseUuid = uuidV4();
            navigationPromises[promiseUuid] = { resolve, reject };

            let msg = {
                code: 'forceUnselectSceneObject',
                data: {},
                uuid: promiseUuid
            }
            options.workers.offscreenCanvasWorker.postMessage(msg);

        } catch (error) {

            navigationModule.forceUnselectSceneObject().then(() => {

                let msg = {
                    code: 'navigationResolve',
                    data: {},
                    uuid: _msg.uuid
                }
                postMessage(msg);
            })


        }

    });
}

export function onFinishPathfindingMovement() {
    let msg = {
        code: MSG_CODE.ACTION_FINISH_PATHFINDING_MOVEMENT,
        data: {}
    }
    postMessage(msg);
}

export function onMoveWithPathfindingFinished() {
    let msg = {
        code: MSG_CODE.MOVE_WITH_PATHFINDING_FINISHED,
        data: {}
    }
    postMessage(msg);
}

export function onRotateCamera() {
    let msg = {
        code: MSG_CODE.ROTATE_CAMERA,
        data: {}
    }
    postMessage(msg);
}

export function onHostAction(_msg) {
    let msg = {
        code: MSG_CODE.RECIVED_ACTION,
        data: _msg,
    }

    postMessage(msg);
}

export function onIndicatorPositionUpdate(_msg) {
    let msg = {
        code: MSG_CODE.ACTION_POSITION_INDICATOR,
        data: _msg,
    }
    postMessage(msg);
}

export function setCurrentAction(_msg) {
    return new Promise((resolve, reject) => {
        try {
            //DOM          
            let validate = window;
            const uuidPromise = uuidV4();
            navigationPromises[uuidPromise] = { resolve, reject };

            let msg = {
                code: 'set_current_action',
                data: {
                    action: _msg.action
                },
                uuid: uuidPromise
            }

            options.workers.offscreenCanvasWorker.postMessage(msg);
        } catch (error) {

            //OffScreen
            navigationModule.setCurrentAction(_msg.data.action);
            // let msg = {
            //     code: 'navigationResolve',
            //     data: navigationModule.getCurrentSceneObject().userData,
            //     uuid: _uuid
            // }

            // postMessage(msg);
        }
    })
}
export function setSelectedUsers(_msg) {
    return new Promise((resolve, reject) => {
        try {
            //DOM          
            let validate = window;
            const uuidPromise = uuidV4();
            navigationPromises[uuidPromise] = { resolve, reject };
            let msg = {
                code: 'set_selected_users',
                data: {
                    users: _msg.selectedUsers
                },
                uuid: uuidPromise
            }
            options.workers.offscreenCanvasWorker.postMessage(msg);
        } catch (error) {

            //OffScreen
            navigationModule.setSelectedUsers(_msg.data.users);
            // let msg = {
            //     code: 'navigationResolve',
            //     data: navigationModule.getCurrentSceneObject().userData,
            //     uuid: _uuid
            // }

            // postMessage(msg);
        }
    })
}
export function changeState(_msg) {
    navigationModule.changeState(_msg.data.state);
}

export function navigateToUser(userId) {
    const user = getAgoraUserIdByPhotoId(userId)
    options.workers.offscreenCanvasWorker.postMessage({ code: "getRemoteLinkpadPosition", data: user });
}
export function goToSceneObject(_msg) {

    return new Promise((resolve, reject) => {
        try {
            //DOM          
            let validate = window;

            const uuidPromise = uuidV4();
            navigationPromises[uuidPromise] = { resolve, reject };

            let msg = {
                code: 'goToSceneObject',
                data: { obj: _msg },
                uuid: uuidPromise
            }

            options.workers.offscreenCanvasWorker.postMessage(msg);
        } catch (error) {

            var obj = navigationModule.getSizeObject(_msg.obj);
            console.log(obj);
            obj.setRotationFromEuler(new THREE.Euler());
            var box = new THREE.Box3().setFromObject(obj);
            var centerPosition = new THREE.Vector3();
            box.getCenter(centerPosition);
            var object3DHeight = (box.max.y - box.min.y) / 2;
            const posObj = new Vector3(_msg.data.data.position[0], centerPosition.y + object3DHeight, _msg.data.data.position[2]);
            navigationModule.checkIntersectionsAroundObject(posObj);
        }
    })
    // options.workers.offscreenCanvasWorker.postMessage({ code: "goToSceneObject", data: {uuid: uuid} });
    // navigationModule.goToSceneObject(uuid);
    // console.log(getSelectedSceneObject(uuid));
    //navigationModule.goToSceneObject();
}

export function inputKeyNavigation(_msg) {
    return new Promise((resolve, reject) => {
        try {
            //DOM          
            let validate = window;

            const uuidPromise = uuidV4();
            navigationPromises[uuidPromise] = { resolve, reject };

            let msg = {
                code: 'inputKeyNavigation',
                data: _msg.data,
                uuid: uuidPromise
            }

            options.workers.offscreenCanvasWorker.postMessage(msg);
        } catch (error) {
            //OffScreen
            /*
            let msg = {
                code: 'navigationResolve',
                data: _msg.data,
                uuid: _msg
            }
            postMessage(msg);
            */
            navigationModule.inputKey(_msg.data);
        }
    })
}

export function getCurrentOrbitUuid(_msg) {
    return new Promise((resolve, reject) => {
        try {
            let validate = window;
            const uuidPromise = uuidV4();
            navigationPromises[uuidPromise] = { resolve, reject };
            let msg = {
                code: 'getCurrentOrbitUuid',
                data: {},
                uuid: uuidPromise
            }
            options.workers.offscreenCanvasWorker.postMessage(msg);

        } catch (error) {
            navigationModule.getCurrentOrbitUuid().then((res) => {
                let msg = {
                    code: 'navigationResolve',
                    data: {
                        res
                    },
                    uuid: _msg.uuid
                }
                postMessage(msg);
            })

        }

    });
}

export function ghostMode(_msg) {
    return new Promise((resolve, reject) => {
        try {
            let validate = window;
            const uuidPromise = uuidV4();
            navigationPromises[uuidPromise] = { resolve, reject };
            let msg = {
                code: 'ghostMode',
                data: {},
                uuid: uuidPromise
            }
            options.workers.offscreenCanvasWorker.postMessage(msg);

        } catch (error) {
            navigationModule.ghostMode().then(() => {
                let msg = {
                    code: 'navigationResolve',
                    data: {},
                    uuid: _msg.uuid
                }
                postMessage(msg);
            })

        }

    });
}